import * as React from "react"
import styled from "styled-components"

const StyledContentContainer = styled.div`
  width: 100%;
  max-width: 552px;
  margin: auto;
  padding: 80px 40px 100px 40px;
`;

const Container = ({children}) => {
  return (
    <StyledContentContainer>
      {children}
    </StyledContentContainer>
  )
}

export default Container;

