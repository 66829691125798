import * as React from "react"
import {Link} from "gatsby"
import Layout from "../components/Layout/Layout";
import Seo from "../components/Core/Seo";
import H1 from "../components/Core/H1";
import Container from "../components/Core/Container";
import styled from "styled-components"
import breakpoints from "../styles/breakpoints";
import Text from "../components/Core/Text";
import colors from "../styles/colors";

const PageContainer = styled.div`
  text-align: center;
  margin-top: 40px;

  @media (min-width: ${breakpoints.md}) {
    margin-top: 200px;
  }
`;

const TitleContainer = styled.div`
  margin-bottom: 20px;
  
  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 32px;
  }
`;

const StyledLink = styled(Link)`
  color: ${colors.white};
  padding-bottom: 2px;
  border-bottom: 1px solid ${colors.white};
  text-decoration: none;
  
  &:hover {
    text-decoration: none;
  }
`;

const NotFoundPage = ({location}) => {
  return (
    <Layout>
      <Seo title={"Page not found"}
           description={"Page not found. Is it lost in the bamboo?"}
           path={location.pathname}/>
      <Container>
        <PageContainer>
          <TitleContainer>
            <H1>Page not found!</H1>
          </TitleContainer>
          <Text>Sorry, we couldn’t find what you were looking for.<br/>Is it lost in the bamboo?</Text>
          <StyledLink to="/">Homepage</StyledLink>
        </PageContainer>
      </Container>
    </Layout>
  )
}

export default NotFoundPage
