import * as React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints";
import fonts from "../../styles/fonts";

const StyledH1 = styled.h1`
  ${fonts.gloryExtraBold};
  text-transform: uppercase;
  font-size: 48px;
  line-height: 64px;
  max-width: 552px;
  margin: auto;

  @media (min-width: ${breakpoints.lg}) {
    font-size: 72px;
    line-height: 72px;
  }
`;

const H1 = ({children}) => {
  return (
    <StyledH1>
      {children}
    </StyledH1>
  )
}

export default H1;

